@charset "UTF-8";

@import "mixin";

.m-inner {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 5px;
  box-sizing: border-box;
  position: relative;
}

.m-section_inner {
  padding: 0 20px 72px 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;
}

.m-slide_caver {
  position: relative;
  &.is_hidden {
    .m-slide_caver {
      &--block {
        width: 0;
      }
    }
  }
  &--block {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.m-slider {
  @include sp {
    width: calc(100vw - 35px);
    overflow: auto;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1) inset;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $colorBlue;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }
  }
  &--inner {
    &-interior {
      @include sp {
        width: 980px;
      }
    }
    &-exterior {
      @include sp {
        width: 980px;
      }
    }
    &-reform {
      @include sp {
        width: 980px;
      }
    }
    &-sign {
      @include sp {
        width: 1500px;
      }
    }
  }
}
